@font-face {
  font-family: 'BayWaSansFont';
  font-weight: 400;
  src:  url('../assets/fonts/BayWaSans-Book.woff2') format('woff2'),
        url('../assets/fonts/BayWaSans-Book.woff') format('woff');
}

@font-face {
  font-family: 'BayWaSansFont';
  font-weight: 600;
  src:  url('../assets/fonts/BayWaSans-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/BayWaSans-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'BayWaSansFont';
  font-weight: 700;
  src:  url('../assets/fonts/BayWaSans-Bold.woff2') format('woff2'),
        url('../assets/fonts/BayWaSans-Bold.woff') format('woff');
}

$html-baseline: 62.5%;

$font-family: 'BayWaSansFont', sans-serif;
$font-size: 1.5rem;
$font-size-s: 1.2rem;

$c-border-input: #e4e4e4;
$c-bg-input: #fbfbfb;
$c-border: #eff0f2;

$spacing-unit: 2rem;
$button-height: 4rem;
$button-icon-height: 5rem;
$fixed-header-height: 44px;

$c-primary: #78b90f;
$c-primary-darken: #669d0c;

$c-error: #ff3333;

:root {
  --c-primary: #78b90f;
  --c-text-button: #000000;
}
