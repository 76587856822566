.lightbox {
  position: fixed;
  width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  color: #111111;
  background-color: #ffffff;
  z-index: 3;
  user-select: none;

  img {
    display: block;
  }
}

.lightbox-body {
  height: 100vh;
  display: flex !important;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.lightbox-buttons {
  display: flex;
  width: 9rem;
  position: absolute;
  top: 1rem;
  right: 0;
}

.lightbox-like-button {
  position: absolute;
  top: 5rem;
  right: 0.5rem;
}

.lightbox-menu {
  position: relative;
  z-index: 1;
  height: $button-icon-height;

  ul {
    position: absolute;
    right: 1rem;
    top: 100%;
    width: auto;
    background-color: #ffffff;
    display: flex;
    padding: 1rem 0;
    flex-direction: column;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.08), 0 2px 5px rgba(0, 0, 0, 0.06), 0 4px 7px rgba(0, 0, 0, 0.04);
    border-radius: 0.5rem;

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      right: 1rem;
      border-left: solid 6px transparent;
      border-right: solid 6px transparent;
      border-bottom: solid 6px #fff;
      filter: drop-shadow(0 -2px 0 rgba(0, 0, 0, 0.04));
      height: 0;
      width: 0;
    }

    li {
      white-space: nowrap;
      line-height: 3rem;
      padding: 0 1.4rem;
      color: #111111;

      &:hover {
        background-color: #f4f4f4;
      }

      a {
        display: block;
        white-space: nowrap;
        color: #111111;
      }

      &.menu-heading {
        opacity: 0.6;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.lightbox-date {
  font-size: 1.1rem;
  line-height: 1;
  opacity: 0.8;
  padding: 0.5rem;
}

.lightbox-image {
  position: relative;
  display: flex;
  flex-direction: column;
}

.lightbox-header {
  padding: 1.4rem 0.5rem 0 $spacing-unit;

  p {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
    width: calc(100% - 10rem);
    line-height: 3rem;
  }

  ol {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.3;

    &.lightbox-img-info-user {
      width: calc(100% - 10rem);

      li {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        position: relative;

        & + & {
          &:after {
            content: '';
            width: 0.4rem;
            min-width: 0.4rem;
            height: 0.4rem;
            border-radius: 50%;
            background-color: #111111;
            margin: 0 1rem;
          }
        }

        &:last-of-type:after {
          display: none;
        }
      }
    }

    &.lightbox-img-info-tags {
      margin-top: 1rem;
      padding-right: 10rem;

      li {
        border-radius: 2.2rem;
        padding: 0 1rem;
        height: 3.2rem;
        line-height: 3.2rem;
        background-color: #cccccc;
        margin: 0 1rem 1rem 0;
        font-size: 1.1rem;
      }
    }
  }
}
