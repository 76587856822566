.list-steps {
  display: flex;
  justify-content: center;
  margin: 0 auto;

  li {
    position: relative;
    white-space: nowrap;
    padding-top: 4rem;
    width: 8rem;
    min-width: 8rem;
    text-align: center;
    font-size: 1.2rem;

    &:before {
      content: '';
      width: 2.8rem;
      height: 2.8rem;
      background-color: #333333;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }


    &:not(:last-child) {
      margin-right: 3rem;

      &:after {
        content: '';
        top: 1.4rem;
        left: 7.5rem;
        width: 4rem;
        height: 2px;
        background-color: #E2E4E8;
        position: absolute
      }
    }
  }

  .list-steps-photo:before {
    mask: url('~../assets/icons/camera.svg') no-repeat center;
    mask-size: 2.8rem;
  }
  .list-steps-overlay:before {
    mask: url('~../assets/icons/image.svg') no-repeat center;
    mask-size: 2.8rem;
  }
  .list-steps-text:before {
    mask: url('~../assets/icons/chat.svg') no-repeat center;
    mask-size: 2.8rem;
  }
}


.list-check {

  li {
    line-height: 1.8rem;
    margin-bottom: .5rem;
    background-repeat: no-repeat;
    background-position: left -.3rem;
    padding-left: 3rem;
    font-size: 1.2rem;

    &.check {
      background-image: url('~../assets/icons/check.svg');
    }

    &.cross {
      background-image: url('~../assets/icons/cross.svg');
    }
  }
}