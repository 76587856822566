.modal {
  position: fixed;
  width: calc(100% - 4rem);
  max-height: calc(100% - 4rem);
  top: $spacing-unit;
  left: $spacing-unit;
  background-color: #ffffff;
  border-radius: .5rem;
  padding: 2.3rem 0;
  box-sizing: border-box;
  z-index: 2;
  overflow: auto;

  .button--close {
    position: absolute;
    right: .7rem;
    top: .7rem;
  }

  &--fullscreen {
    width: 100%;
    height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    border-radius: 0;

    .modal-content {

      p {
        font-size: 1.5rem;
      }
    }
  }
}

.modal-content {
  padding: 0 $spacing-unit;
  width: 100%;
  box-sizing: border-box;

  p {
    margin-bottom: $spacing-unit;
    font-size: 1.2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modal-shade {
  position: absolute;
  opacity: .5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .2);
  backdrop-filter: blur(10px);
}

.modal-loading-info {
  display: flex;
  align-items: center;
  padding: $spacing-unit $spacing-unit 0;

  .progress-container {
    position: relative;
    width: 50%;
    align-self: center;
  }
}

.confirm-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  z-index: 1;

  > div {
    padding: 0 3rem;
    box-sizing: border-box;
  }

  button {
    width: 100%;
    margin: $spacing-unit auto;
  }
}
