$spaceamounts: (0, 10, 20, 30, 40);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
    .h-m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .h-p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.h-center {
  text-align: center;
}

.h-strong {
  font-weight: bold;
}

.h-flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h-page-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: $spacing-unit 5rem;
  height: 100%;
  box-sizing: border-box;
}
