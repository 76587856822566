.cards {
  display: flex;
  margin: 3rem 0;
  z-index: 1;
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  background-color: #F7F9FC;
}

.card {
  flex: 1;
  padding: 1.5rem;
  box-sizing: border-box;
  border-radius: 1rem;
  position: relative;
  font-size: 1.2rem;
  line-height: 1.7rem;
  transition: background-color .2s;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:first-child {
    border-radius: 1rem 0 0 1rem;
  }

  &:last-child {
    border-radius: 0 1rem 1rem 0;
  }

  h4 {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: .5rem;
  }

  &.selected {
    background-color: #ECEEF4;

    h4 {
      color: $c-primary;
    }
  }

  img {
    max-width: 100%;
    margin: auto;
  }
}
