button,
.button {
  border-radius: 2.2rem;
  border: 2px solid transparent;
  padding: 0 1.4rem;
  text-align: center;
  font-size: $font-size;
  font-family: $font-family;
  font-weight: normal;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $button-height;
  line-height: $button-height;
  background-color: #ffffff;
  text-decoration: none;
  box-sizing: border-box;

  &:hover {
    text-decoration: none;
  }

  &[disabled] {
    color: #999999 !important;
    background-color: #cccccc !important;

    i {
      color: #999999 !important;
      opacity: 0.2;
    }
  }

  &--primary {
    background-color: $c-primary;
    color: #000000;
    width: 100%;

    &:hover {
      color: #ffffff;
      background-color: $c-primary-darken;
    }
  }

  &--secondary {
    color: #000000;
    width: 100%;
    border-color: $c-primary;

    &:hover {
      color: #ffffff;
      border-color: $c-primary-darken;
      background-color: $c-primary-darken;
    }

    &[disabled] {
      border-color: #cccccc !important;
    }
  }

  &--small {
    box-sizing: border-box;
    font-size: 1.1rem;
    padding: 0 1rem;
    height: 3.2rem;
    min-height: 3.2rem;
    line-height: 1;
    width: auto;
  }

  &--filter {
    width: 80px;
  }

  &--link {
    background: none;
    border: none;
    padding: 0;
    display: inline; // fallback
    display: contents;
    color: $c-primary;

    &:hover {
      color: $c-primary-darken;
      text-decoration: underline;
    }
  }

  &--close {
    width: $button-icon-height;
    height: $button-icon-height;
    background: transparent url('~../assets/icons/close.svg') no-repeat center center;
    background-size: 1.8rem;
  }

  &--like,
  &--unlike {
    color: $c-primary;
    display: flex;
    align-items: center;
    font-size: 1.4rem;

    i {
      mask: url('~../assets/icons/like.svg') no-repeat left center;
      mask-size: contain;
      background-color: $c-primary;
      width: 1.8rem;
      height: 1.8rem;
      margin: 0 1rem 0 0;
    }
  }

  &--unlike {
    i {
      mask: url('~../assets/icons/like-full.svg') no-repeat left center;
      mask-size: contain;
    }
  }

  &--menu {
    width: 4rem;
    height: 4rem;
    background: transparent url('~../assets/icons/menu.svg') no-repeat center center;
    background-size: 1.8rem;
  }

  &--close-dark {
    width: 4rem;
    height: 4rem;
    background: transparent url('~../assets/icons/close-dark.svg') no-repeat center center;
    background-size: 1.8rem;
  }

  &--retake {
    background: transparent url('~../assets/icons/restart.svg') no-repeat left 1rem center;
    background-size: 1.8rem;
    padding: 0 1rem 0 3.8rem;
  }

  &--next,
  &--next[disabled] {
    background: transparent url('~../assets/icons/arrow-right.svg') no-repeat right 1rem center !important;
    background-size: 1.8rem;
    padding: 0 3.8rem 0 1rem;
  }

  &--previous {
    background: transparent url('~../assets/icons/arrow-left.svg') no-repeat left 1rem center;
    padding: 0 1rem 0 3.8rem;
  }

  &--pause {
    background: transparent url('~../assets/icons/pause.svg') no-repeat left 1rem center;
    background-size: 1.8rem;
    padding: 0 1rem 0 2.8rem;
  }

  &--play {
    background: transparent url('~../assets/icons/play.svg') no-repeat left 1rem center;
    background-size: 1.8rem;
    padding: 0 1rem 0 2.8rem;
  }

  &--edit {
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 50%;
    background-color: $c-primary;
    padding: 0;

    i {
      mask: url('~../assets/icons/edit.svg') no-repeat center;
      background-color: #000000;
      width: 1.8rem;
      height: 1.8rem;
      display: block;
      margin: 0 auto;
    }
  }

  &--slider {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: $c-primary;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    i {
      mask: url('~../assets/icons/arrow-left.svg') no-repeat center;
      background-color: #000000;
      width: 1.8rem;
      height: 1.8rem;
      display: block;

      + i {
        mask: url('~../assets/icons/arrow-right.svg') no-repeat center;
        margin-left: -2px;
      }
    }
  }

  &--slider-v {
    flex-direction: column;

    i {
      transform: rotate(90deg);

      + i {
        margin: -2px 0 0 0;
      }
    }
  }

  &--save {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      mask: url('~../assets/icons/save.svg') no-repeat center;
      width: 1.8rem;
      height: 1.8rem;
      display: block;
      background-color: #000000;
      margin-right: 1rem;
    }
  }

  &--restart {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      mask: url('~../assets/icons/restart.svg') no-repeat center;
      width: 1.8rem;
      height: 1.8rem;
      mask-size: 1.8rem;
      display: block;
      background-color: #000000;
      margin-right: 1rem;
    }
  }

  &--new {
    position: fixed;
    bottom: 3rem;
    left: 3rem;
    z-index: 1;
  }

  &--fixed {
    position: fixed;
    bottom: 3rem;
    z-index: 1;
    width: $button-icon-height;
    height: $button-icon-height;
    border-radius: 50%;
    background-color: $c-primary;
    padding: 0;
    opacity: 0;
    transform: translateY(2rem);
    visibility: hidden;
    transition: all 0.2s;

    i {
      background-color: #000000;
      width: 3rem;
      height: 3rem;
      display: block;
      margin: 0 auto;
    }

    &.hidden {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }

  &--photobooth {
    right: 3rem;

    i {
      mask: url('~../assets/icons/camera.svg') no-repeat center;
      mask-size: 3rem;
    }

    &:hover i {
      background-color: #ffffff;
    }
  }

  &--filter {
    right: 10rem;

    i {
      mask: url('~../assets/icons/tag-filter.svg') no-repeat center;
      mask-size: 3rem;
    }

    &:hover i {
      background-color: #ffffff;
    }
  }

  &--tag {
    background-color: #cccccc;
    border: none;
    margin: 0 1rem 1rem 0;

    span {
      width: 1.8rem;
      height: 1.8rem;
      margin-left: 1rem;
      background: #7f7f7f url('~../assets/icons/close-grey.svg') no-repeat center center;
      background-size: 1rem;
      border-radius: 50%;
    }
  }
}
