.gallery {
  background-color: #eeeeee;
  padding: 0;
  min-height: 100%;

  img {
    max-width: calc(100% - 1rem);
    max-height: calc(100% - 1rem);
    object-fit: contain;
    border-radius: 2px;
  }

  .lightbox-image {
    margin: auto;
    overflow: hidden;
    height: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }
}

.gallery-header {
  position: relative;
  background-color: #ffffff;
  padding: $spacing-unit 3rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06), 0 2px 2px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.02);

  h1 {
    margin-bottom: 0;
  }
}

.gallery-sort {
  display: flex;
  flex-wrap: wrap;
  margin: $spacing-unit / 2 3rem $spacing-unit / 2;
  position: relative;
}

.gallery-filter {
  display: flex;
  flex-wrap: wrap;
  margin: $spacing-unit 3rem 0;
  position: relative;

  .button--close {
    padding: 0px;
    position: absolute;
    right: 0.7rem;
    top: 0.7rem;
    z-index: 10;
  }
}

.gallery-filter-modal {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--mobile {
    height: 100vh;

    ul {
      padding: 3rem;
      -webkit-overflow-scrolling: touch;
      overflow: auto;
      height: calc(100% + 1px);
    }

    .gallery-filter-modal-button {
      padding: 2rem 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.08), 0 2px 5px rgba(0, 0, 0, 0.06), 0 4px 7px rgba(0, 0, 0, 0.04);

      button {
        margin: 0;
      }
    }
  }

  &--desktop {
    position: absolute;
    left: 0;
    top: 100%;
    padding: $spacing-unit;
    background-color: #ffffff;
    z-index: 1;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06), 0 2px 2px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.02);
  }

  ul {
    li {
      line-height: 4rem;

      input[type='checkbox'] {
        margin-right: 1rem;
      }

      .checkbox + label:before {
        top: 0.7rem;
      }

      .checkbox:checked + label:after {
        top: 17px;
      }
    }
  }
}

.gallery-body {
  padding: 0 $spacing-unit $spacing-unit;
}

.gallery-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.07), 0 4px 4px rgba(0, 0, 0, 0.035);

  img {
    object-fit: contain;
  }
}

.gallery-autoPlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 10rem;
  right: $spacing-unit;
  z-index: 1;
  background: #ffffff;
  border-radius: 50%;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: none; // Enable z-indexes
}

// Image swipe
.slides {
  position: relative;

  .slick-prev,
  .slick-next {
    position: absolute;
    top: calc(50% + 4rem);
    z-index: 2;
    border-radius: 50%;
    width: $button-icon-height;
    height: $button-icon-height;
    background-color: $c-primary;
    transform: rotate(90deg) translateY(-50%);
    transition: background-color 0.2s cubic-bezier(0.32, 0, 0.67, 0);

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      mask: url('~../assets/icons/caret.svg') no-repeat center;
      background-color: #111111;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 0.2s cubic-bezier(0.32, 0, 0.67, 0);
    }

    &:hover {
      background-color: $c-primary-darken;

      &:before {
        background-color: #ffffff;
      }
    }
  }

  .slick-prev {
    left: 5%;
    transform: rotate(-90deg) translateY(-50%);
  }

  .slick-next {
    right: 5%;
  }
}

@media screen and (min-width: 1024px) {
  .slick-arrow {
    display: block !important;
  }
}
