
.editor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &.group {
    background: black;
  }

  &.single {
    // background-color: rgba(0, 0, 0, 0.3);
  }
}


.editor-stepper {
  display: flex;
  background-color: #FBFBFB;
  height: $button-icon-height;
  min-height: $button-icon-height;
  align-items: center;

  li {
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.8rem;
    font-weight: 600;
    color: #D4D8DF;
    flex: 1;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;

    div {
      width: $spacing-unit;
      height: $spacing-unit;
      margin: 0 auto;
      background-color: #d4d8df;
    }

    &.editor-stepper-filter div {
      mask: url('~../assets/icons/filter.svg') no-repeat center;
    }

    &.editor-stepper-text div {
      mask: url('~../assets/icons/chat.svg') no-repeat center;
    }

    &.editor-stepper-position div {
      mask: url('~../assets/icons/crop.svg') no-repeat center;
    }

    &.editor-stepper-preview div {
      mask: url('~../assets/icons/preview.svg') no-repeat center;
    }

    &.active,
    &.completed {
      color: $c-primary;
      border-bottom-color: $c-primary;

      div {
        background-color: $c-primary;
      }
    }
  }
}

.editor-overlay-wrapper {
  height: 100%;
  overflow: hidden;
}

.editor-overlay {
  position: relative;
  text-align: center;
  overflow: hidden;
  height: 100%;
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.3);
  margin: auto;

  &:not(.readOnly) {
    margin: 1rem auto;
    max-width: calc(100% - #{$spacing-unit});
  }

  &.is-landscape {
    width: 100%;
    height: fit-content;
  }

  &.readOnly {
    background-color: rgba(0, 0, 0, 0);
    height: 100%;
  }

  &.alert {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.editor-overlay-resizing-box {
  position: absolute;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mask-repeat: no-repeat;
  }
}

.editor-overlay-images-container {
  position: relative;
  height: 100%;
}

.editor-overlay-photo {
  max-width: 100%;
  max-height: 100%;
  display: block;
  // object-fit: contain;
}

.editor-overlay-list {
  height: 8.6rem;
  min-height: 8.6rem;
  width: 100%;
  overflow: hidden;
  background-color: #ECEEF4;

  ul {
    overflow: auto;
    display: flex;
    padding: 1.3rem $spacing-unit;
    width: 100%;
    box-sizing: border-box;

    li {
      margin-right: 1.5rem;
      position: relative;
      width: 6rem;
      height: 6rem;

      &:before {
        content: '';
        border-radius: 1rem;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        border: .2rem solid transparent;
        pointer-events: none;
        transition: border .2s;
      }

      span {
        position: absolute;
        width: $spacing-unit;
        height: $spacing-unit;
        top: -.5rem;
        right: -.5rem;
        border-radius: 50%;
        background-color: $c-primary;
        opacity: 0;
        transition: all .2s;
        transform: scale(.6);

        &:after {
          content: '';
          width: .8rem;
          height: .3rem;
          display: block;
          border-left: 2px solid #ffffff;
          border-bottom: 2px solid #ffffff;
          transform: rotate(-45deg);
          margin: .6rem .5rem;
        }
      }

      &.active {

        &:before {
          border-color: #ffffff;
        }

        span {
          opacity: 1;
          transform: scale(1);
        }
      }

      img {
        width: 6rem;
        height: 6rem;
        object-fit: cover;
        border-radius: 1rem;
      }
    }
  }
}

.editor-overlay-opacity {
  position: absolute;
  top: 7rem;
  right: 4rem;
  display: flex;
  flex-direction: column;
  transform: rotate(-90deg);
  width: 35vh;
  transform-origin: 100% 0%;
  justify-content: center;
  padding-right: $spacing-unit;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    width: 3rem;
    height: 3rem;
    background: url('~../assets/icons/contrast.svg') no-repeat center;
    background-size: 2.4rem;
    transform: rotate(90deg);
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.editor-overlay-opacity-value {
  position: absolute;
  right: 100%;
  width: 4rem;
  height: 4rem;
  box-sizing: border-box;
  padding: 1rem 0;
  background-color: #ffffff;
  border-radius: 1rem;
  font-size: 1.4rem;
  transform: rotate(90deg);
  text-align: center;
}

.editor-overlay-opacity-range {
  margin: 1rem $spacing-unit;
  width: calc(100% - 4rem);
}

.editor-message {
  padding: $spacing-unit 4vw;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;

  h1 {
    color: #008C46;
    font-weight: 400;
    padding: 0 1.4rem;
  }
}

.editor-message-input {
  position: relative;

  ul {
    background-color: #EFF0F2;
    border-radius: 1rem;
    border: 1px solid #F0F3F5;
    box-sizing: border-box;
    margin-top: 1rem;
    overflow: auto;

    li {
      padding: 0 1.4rem;
      height: 4.8rem;
      line-height: 1.3;
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      color: #333333;
      border-bottom: 1px solid #E6E6E6;
      box-sizing: border-box;
      transition: background-color .2s;

      &:last-child {
        border-bottom: none;
      }

      &.active {
        background-color: darken(#EFF0F2, 15);
      }
    }
  }
}

.editor-message-length {
  position: absolute;
  right: 0;
  top: 8rem;
  font-size: 1.1rem;
  line-height: $spacing-unit;

  &.full {
    color: $c-error;
  }
}

.editor-position {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $spacing-unit 4vw;
  user-select: none;
}

.editor-position-container-wrapper {
  height: 0;
  padding-top: 13/19 * 100%;
  background: white;
  position: relative;
  margin-top: $spacing-unit;
}

.editor-position-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editor-position-slider-container {
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  font-size: calc(1em + 1vw);
  display: flex;
  flex-direction: column;

  .button--slider-h {
    position: absolute;
    top: calc(100% - 30px);
    z-index: 1;
  }

  .button--slider-v {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
  }

  .editor-position-slider-intro {
    font-size: 1.6vw;
    line-height: 1.3;
    text-align: center;
    padding: 1vw 10vw 3vw;
    background: #ffffff;
    z-index: 1;
  }
}

.group-editor {
  width: 100%;
  height: 100%;
  max-height: calc(100% - 10rem); // viewport height minus header/footer
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.group-editor--container {
  height: fit-content;
  width: fit-content;
  margin: 1rem;
  position: relative;
  max-height: calc(100% - #{$spacing-unit});
}

.group-editor--photo {
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.editor-position-slider {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  > div {
    width: 50%;
    box-sizing: border-box;
  }

  .editor-position-message-container {
    position: relative;
    height: fit-content;

    h1 {
      font-size: 4.6vw;
      line-height: 1.2;
      color: #008C46;
      font-weight: 400;
      margin: 2vw 2vw 3vw 6vw;
    }

    span {
      font-size: 3.2vw;
      line-height: 1.4;
      display: block;
      margin: 2vw 2vw 2vw 6vw;
    }

    &.right h1,
    &.right span {
      margin: 2vw 6vw 2vw 2vw;
    }
  }

  .editor-position-overlay-container {
    position: relative;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .position-editor & {
    user-select: none;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: calc(50% - 1px);
      width: 0;
      height: 100%;
      opacity: .2;
      border-left: 1px dashed #184C69;
      border-right: 1px dashed #184C69;
      z-index: 0;
    }
  }
}

.editor-preview-poster-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.editor-preview-poster {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  height: 100vw;
  width: 100vw;
  max-height: calc(100vh - 266px); // preview if space available is less than 100vw

  .editor-preview-poster-aspect-ratio {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .editor-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.editor-overlay-images-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;

  // On this step we use a square of 100vw * 100vw to fit image (3/4 of the height) and text (1/4 of the height)
  // We need this height fix whenever the image width*.75 is greater than the height of the image
  .is-landscape-preview & {
    height: auto;
  }
}

.editor-preview-info {
  margin: 1rem;
}

.editor-preview-save {
  margin: $spacing-unit auto;
}

.editor-message-text {
  margin: 1rem 0;
  text-align: center;
  height: 25vw;
  min-height: 25vw;
  font-weight: normal;
   // proportional font size calculated from ratio and font size used in backend
  font-size: calc(100vw / 24.1);
  line-height: calc(25vw / 4);
  word-break: break-word;
}

.editor-preview-image-only {
  max-height: 100%;
}

.editor-controls {
  height: $button-icon-height;
  min-height: $button-icon-height;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FBFBFB;
  border-top: 1px solid #EEEEEE;
}

.editor-is-saving {
  position: relative;

  .loader-fullscreeen {
    margin-top: -60px;
  }
}
