* {
  outline: none;
}

#rootBaywa {
  font-size: $html-baseline;
  scroll-behavior: smooth;
}

body {
  font-size: $font-size;
  line-height: 2.2rem;
  font-family: $font-family;
  font-weight: 400;
  color: #333333;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

main {
  width: 100%;
  height: 100%;
  background-color: #ffffff;

  &.root {
    background-color: #eeeeee;
  }
}

.resizer {
  width: 100vw;
  height: 100%;
}

section {
  padding: 3rem;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #ffffff;

  &.intro-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 0;
    min-height: 100%;

    .intro-page-heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 3rem;
    }
  }
}

h1 {
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

a {
  color: $c-primary;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $c-primary-darken;
    text-decoration: underline;
  }
}

.disabled,
:disabled,
[disabled] {
  filter: grayscale(1);
  opacity: .7;
}

.intro-page-tos {
  padding: 0 3rem;
  font-size: 1.2rem;
  margin-top: $spacing-unit;

  .button--link {
    font-size: 1.2rem;
  }
}

.info-box {
  background-color: #EFF0F2;
  border-radius: 1rem;
  text-align: center;
  padding: 1rem 3rem;
  margin-bottom: $spacing-unit;
  font-size: $font-size-s;
  width: 100%;
  box-sizing: border-box;
}
