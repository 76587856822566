// Input range

input[type=range] {
  appearance: none;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #ffffff;
}

input[type=range]::-webkit-slider-thumb {
  height: 3rem;
  width: 3rem;
  border-radius: 1.5rem;
  background: #ffffff;
  cursor: pointer;
  appearance: none;
  margin-top: -1.4rem;
}

// Input text

input[type=text],
textarea {
  background-color: #EFF0F2;
  border-radius: 1rem;
  border: 1px solid #F0F3F5;
  box-shadow: none;
  border: none;
  padding: 0 1.4rem;
  height: 4.8rem;
  min-height: 4.8rem;
  font-size: 1.5rem;
  color: #333333;
  width: 100%;
  box-sizing: border-box;
  font-family: $font-family;
}

textarea {
  padding: 1rem 1.4rem;
  line-height: 1.2;
  height: 7.4rem;
}

// Input with button

.input-button {
  position: relative;

  button {
    position: absolute;
    top: .7rem;
    right: 1rem;
  }
}

.input-field {
  margin-bottom: $spacing-unit;

  label {
    margin-bottom: .5rem;
  }
}

label {
  display: block;
  font-weight: 600;

  .intro-page-tos & {
    font-weight: normal;
    margin-bottom: $spacing-unit;
  }
}

select {
  display: block;
  font-size: 1.5rem;
  font-family: $font-family;
  color: #333333;
  line-height: 1.3;
  height: 4.8rem;
  padding: 0 1.4rem;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #F0F3F5;
  border-radius: 1em;
  appearance: none;
  background-color: #EFF0F2;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxMDI0IiB3aWR0aD0iNzY3LjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMzg0bDM4My43NSAzODMuNzVMNzY3LjUgMzg0SDB6Ii8+PC9zdmc+');
  background-repeat: no-repeat;
  background-position: right .7em top 50%;
  background-size: .65em auto;

  option {
    font-weight:normal;
  }
}

select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
