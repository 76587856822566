.checkbox-wrapper {
  position: relative;
  padding-left: 4rem;
  line-height: 4rem;

  & + & {
    margin-top: 2rem;
  }

  &--multiline {
    line-height: 2.2rem;

    .checkbox {
      + span:before {
        top: 0;
      }

      &:checked + span:after {
        top: 10px;
      }
    }
  }
}

.checkbox {
  position: absolute;
  opacity: 0;

  & + span {
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 6px;
      width: 2.2rem;
      height: 2.2rem;
      box-sizing: border-box;
      background: $c-bg-input;
      border-radius: 2px;
      border: 1px solid #cccccc;
    }
  }

  &:checked + span {
    &:before {
      background: $c-primary;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px $c-primary;
    }

    &:after {
      content: '';
      position: absolute;
      left: 6px;
      top: 16px;
      background: #000000;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 #000000, 4px 0 0 #000000, 4px -2px 0 #000000, 4px -4px 0 #000000, 4px -6px 0 #000000, 4px -8px 0 #000000;
      transform: rotate(45deg);
    }
  }
}
