$loaderSize: 60px;
$borderSize: 4px;
$duration: .8s;

.loader {
  width: $loaderSize;
  height: $loaderSize;
  border: $borderSize solid transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::before {
    content: '';
    border: $borderSize solid rgba($c-primary, 0.5);
    border-radius: 50%;
    width: $loaderSize * 1.2;
    height: $loaderSize * 1.2;
    position: absolute;
    top: -(($loaderSize * 1.2) / 2) + ($loaderSize / 2) - $borderSize;
    left: -(($loaderSize * 1.2) / 2) + ($loaderSize / 2) - $borderSize;
    animation: loaderScale $duration ease-out infinite;
    animation-delay: $duration;
    opacity: 0;
  }

  &::after {
    content: '';
    border: $borderSize solid $c-primary;
    border-radius: 50%;
    width: $loaderSize;
    height: $loaderSize;
    position: absolute;
    top: -$borderSize;
    left: -$borderSize;
    animation: loaderScale $duration ease-out infinite;
    animation-delay: $duration / 2;
    opacity: 0;
  }
}

@keyframes loaderScale {

  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

// Progress bar animation

.progress-infinite {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  height: 6px;
  width: 100%;

  &:before {
    height: 6px;
    width: 100%;
    background-color: $c-primary;
    content: '';
    animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes running-progress {
  0% {
    margin-left: 0;
    margin-right: 100%;
  }
  50% {
    margin-left: 25%;
    margin-right: 0;
  }
  100% {
    margin-left: 100%;
    margin-right: 0;
  }
}

.loader-circular {
  border-radius: 50%;
  width: $spacing-unit;
  height: $spacing-unit;
  position: relative;
  border-top: 2px solid rgba(120, 185, 15, .2);
  border-right: 2px solid rgba(120, 185, 15, .2);
  border-bottom: 2px solid rgba(120, 185, 15, .2);
  border-left: 2px solid $c-primary;
  animation: circularLoader 1.1s infinite linear;
}

@keyframes circularLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Baywa Loader

.loader-fullscreeen {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  svg {
    animation: 2s linear infinite svg-animation;
    max-width: 100px;
  }

  circle {
    animation: 1.8s ease-in-out infinite both circle-animation;
    display: block;
    fill: transparent;
    stroke: $c-primary;
    stroke-dasharray: 203;
    stroke-dashoffset: 200;
    stroke-width: 3px;
    transform-origin: 50% 50%;
  }
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 200;
    transform: rotate(15);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(60deg);
  }

  100% {
    stroke-dashoffset: 200;
    transform: rotate(360deg);
  }
}
